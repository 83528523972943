import jwtDecode from 'jwt-decode';

export const TOKEN_KEY = '@Token';
export const DATA_PERFIL = '@dados-Perfil';
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = (token, perfil) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(DATA_PERFIL, JSON.stringify(perfil));
};

export const getData = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  return jwtDecode(token);
};

export const getPerfil = () => {
  const perfil = JSON.parse(localStorage.getItem(DATA_PERFIL));
  return perfil;
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};
