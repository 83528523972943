import React from 'react';
import Navbar from '../NavBar'; // Ajuste o caminho conforme necessário
import { MDBContainer } from 'mdbreact';

export const Layout = ({ children }) => (
  <>
    <Navbar />
    
    {children}
  </>
);