import React, { useEffect, useState } from 'react';

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBIcon,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBSpinner,
  MDBDatePicker,
  MDBTabContent,
  MDBTabPane,
  MDBBtn,
  MDBInput,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
} from 'mdbreact';
import Select from 'react-select';
import { format, isPast } from 'date-fns';
import { Link, useHistory, useParams } from 'react-router-dom';
import Api from '../../../services/api';

import HorizontalBar from '../../../components/Graficos/HorizontalBar';
import Pie from '../../../components/Graficos/Pie';
import { Bars } from '../../../components/Graficos/Bars';
import Minimalist from '../../../components/Graficos/Minimalist';
import Polar from '../../../components/Graficos/Polar';
import Line from '../../../components/Graficos/Line';
import { da } from 'date-fns/locale';

export default function ShowProjetos() {
  const history = useHistory();
  const [projeto, setProjeto] = useState(null);
  const { uuid } = useParams();
  const [typeEvent, setTypeEvent] = useState([]);
  const [typeEventSelecionado, setTypeEventSelecionado] = useState(null);
  const [novoEventoModal, setNovoEventoModal] = useState(false);
  const [captacaoStartDate, setCaptacaoStartDate] = useState(new Date());

  const [listaEventos, setListaEventos] = useState(null);

  const [reativar, setReativar] = useState(null);
  const [arquivar, setArquivar] = useState(null);

  const [dadosCaptados, setDadosCaptados] = useState(null);

  const [dataBars, setDataBars] = useState(null);

  const buscaProjeto = async () => {
    try {
      const result = await Api.get(`/projetos/${uuid}`);
      setProjeto(result.data);

      listaDadosCaptados(result.data.project_id);
      graficoBarras(result.data.project_id);
      // buscaEventos(result.data.project_id,true)
    } catch (error) {
      console.error(error);
    }
  };

  const redirecionarEvent = (event_id) => {
    history.push(`/event/${uuid}/${event_id}`);
  };

  const buscaEventos = async (project_id, enable = true) => {
    try {
      const result = await Api.get('/events', {
        params: {
          project_id,
          enable: enable.toString(), // Convertendo boolean para string
        },
      });
      setListaEventos(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const AtivarArquivarEvento = async (event_id, enable) => {
    try {
      const result = await Api.put(`/event/${event_id}`, {
        enable: enable.toString(),
      });
      setReativar(null);
      setArquivar(null);
      if (activeItemJustified === '1') {
        buscaEventos(projeto.project_id, true);
      }
      if (activeItemJustified === '2') {
        buscaEventos(projeto.project_id, false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [activeItemJustified, setActiveItemJustified] = useState('1');
  const toggleJustified = (tab) => {
    if (activeItemJustified !== tab) {
      setActiveItemJustified(tab);
    }
  };

  const listaDadosCaptados = async (project_id) => {
    const result = await Api.get(`/dados-captados/${project_id}`);

    setDadosCaptados(result.data);
  };

  const graficoBarras = async (project_id) => {
    const result = await Api.get(`/projeto-grafico-barras/${project_id}`);
    const dataBars = [];
    const labels = [];
    result.data.forEach((t) => {
      dataBars.push(t.n_page_load);
      labels.push(t.name);
    });

    setDataBars({
      labels: labels,
      data: dataBars,
      header: 'Your Header Here', // Exemplo de como você pode passar um cabeçalho
    });
  };

  const buscaTypeEvent = async () => {
    try {
      const select = [];
      const result = await Api.get(`/type-event`);
      result.data.forEach((item) => {
        select.push({
          value: item.event_type_id,
          label: `${item.name} - ${item.description}`,
          capture_date: item.capture_date,
          sales_date: item.sales_date,
        });
      });
      setTypeEvent(select);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (novoEventoModal === true && typeEvent.length === 0) {
      buscaTypeEvent();
    }
  }, [novoEventoModal]); // Adiciona uuid como dependência para reagir a mudanças

  useEffect(() => {
    buscaProjeto();
  }, [uuid]); // Adiciona uuid como dependência para reagir a mudanças

  useEffect(() => {
    if (projeto !== null) {
      if (activeItemJustified === '1') {
        buscaEventos(projeto.project_id, true);
      } else if (activeItemJustified === '2') {
        buscaEventos(projeto.project_id, false);
      }
    }
  }, [activeItemJustified, projeto]); // Adicione 'projeto' como dependência
  return (
    <MDBContainer fluid>
      {projeto && (
        <>
          {/* <MDBCard key={projeto.id}>
                  <MDBCardBody> */}

          <MDBRow>
            <MDBCol size="12" md="3" className="p-2">
              <MDBCard className="text-gray h-100">
                <MDBCardHeader>
                  <MDBRow>
                    <MDBCol className="font-weight-bolder mx-2 grey-text h4-responsive">
                      PROJETO
                    </MDBCol>
                    {/* <MDBCol className='grey-text text-right'><i>
                    Criado em:
                    {eventSelected?.project.created_at && format(new Date(eventSelected.project.created_at), 'dd/MM/yyyy')}
                  </i>
                  </MDBCol> */}
                  </MDBRow>
                </MDBCardHeader>
                <MDBCardBody className="h-100">
                  {dadosCaptados === null ? (
                    <MDBRow center>
                      <MDBSpinner red />
                    </MDBRow>
                  ) : (
                    <>
                      <MDBRow className="h5-responsive text-jl font-weight-bold">
                        <MDBCol>{projeto.name}</MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol>Nickname: {projeto.nick_name}</MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol className="text-right grey-text">
                          <i>
                            Criação:{' '}
                            {format(
                              new Date(projeto.created_at),
                              'dd/MM/yyyy HH:mm'
                            )}
                          </i>
                        </MDBCol>
                      </MDBRow>
                    </>
                  )}
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol size="12" md="3" className="p-2">
              <MDBCard className="text-gray h-100">
                <MDBCardHeader>
                  <MDBRow>
                    <MDBCol className="font-weight-bolder mx-2 grey-text h4-responsive">
                      DESCRIÇÃO
                    </MDBCol>
                    {/* <MDBCol className='grey-text text-right'><i>
                    Criado em:
                    {eventSelected?.project.created_at && format(new Date(eventSelected.project.created_at), 'dd/MM/yyyy')}
                  </i>
                  </MDBCol> */}
                  </MDBRow>
                </MDBCardHeader>
                <MDBCardBody className="h-100">
                  <MDBRow className="bg-gray">
                    <MDBCol>
                      {dadosCaptados === null ? (
                        <MDBRow center>
                          <MDBSpinner red />
                        </MDBRow>
                      ) : (
                        projeto.description
                      )}
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol size="12" md="3" className="p-2">
              <MDBCard className="text-gray h-100">
                <MDBCardHeader>
                  <MDBRow>
                    <MDBCol className="font-weight-bolder mx-2 grey-text h4-responsive">
                      DADOS CAPTADOS
                    </MDBCol>
                    {/* <MDBCol className='grey-text text-right'><i>
                    Criado em:
                    {eventSelected?.project.created_at && format(new Date(eventSelected.project.created_at), 'dd/MM/yyyy')}
                  </i>
                  </MDBCol> */}
                  </MDBRow>
                </MDBCardHeader>
                <MDBCardBody className="h-100">
                  <MDBRow center className="mb-2 ">
                    <MDBCol className="font-weight-bold h5 grey-text text-right">
                      {dadosCaptados === null ? (
                        <MDBRow center>
                          <MDBSpinner red />
                        </MDBRow>
                      ) : (
                        dadosCaptados?.registers
                      )}
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol className="text-right grey-text">
                      <i>Registros</i>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol size="12" md="3" className="p-2">
              <MDBCard className="text-gray h-100">
                <MDBCardHeader>
                  <MDBRow>
                    <MDBCol className="font-weight-bolder mx-2 grey-text h4-responsive">
                      COLABORADORES
                    </MDBCol>
                    {/* <MDBCol className='grey-text text-right'><i>
                    Criado em:
                    {eventSelected?.project.created_at && format(new Date(eventSelected.project.created_at), 'dd/MM/yyyy')}
                  </i>
                  </MDBCol> */}
                  </MDBRow>
                </MDBCardHeader>
                <MDBCardBody className="h-100">
                  <MDBRow start className="font-weight-bold"></MDBRow>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="12" md="5" className="p-2">
              <MDBCard className="text-gray h-100">
                <MDBCardBody className="h-100 d-flex justify-content-center align-items-center">
                  {dataBars === null ? (
                    <MDBSpinner red big />
                  ) : (
                    <Bars
                      labels={dataBars?.labels}
                      data={dataBars?.data}
                      header={dataBars?.header}
                    />
                  )}
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol size="12" md="7" className="p-2 ">
              <MDBCard className="text-gray h-100 w-100 d-flex align-items-center justify-content-center p-3">
                <Minimalist />
              </MDBCard>
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol size="12" md="12" className="p-2">
              <MDBCard className="text-gray h-100">
                <MDBCardHeader className="m-0 p-0">
                  <MDBRow
                    b
                    className="m-2 d-flex justify-content-between align-items-center"
                  >
                    <MDBCol
                      size="8"
                      className="font-weight-bolder grey-text h4-responsive d-flex justify-content-start align-items-center"
                    >
                      <div className="mr-3">EVENTOS</div>
                      <Select
                        className="col-4"
                        options={[
                          { label: 'ATIVOS', value: '1' },
                          { label: 'ARQUIVADOS', value: '2' },
                          { label: 'DASHBOARD', value: '3' },
                        ]}
                        defaultValue={{ label: 'ATIVOS', value: '1' }}
                        onChange={(e) => toggleJustified(e.value)}
                      />
                    </MDBCol>
                    <MDBCol
                      size="4"
                      className="d-flex justify-content-end align-items-center"
                    >
                      <MDBBtn
                        className="m-0 d-flex justify-content-center align-items-center font-weight-bolder"
                        color="dark-green"
                        onClick={() => setNovoEventoModal(true)}
                        size="sm"
                      >
                        <MDBIcon
                          className="mr-2"
                          far
                          icon="plus-square"
                          size="2x"
                        />{' '}
                        NOVO EVENTO
                      </MDBBtn>
                    </MDBCol>
                    {/* <MDBCol className='grey-text text-right'><i>
                    Criado em:
                    {eventSelected?.project.created_at && format(new Date(eventSelected.project.created_at), 'dd/MM/yyyy')}
                  </i>
                  </MDBCol> */}
                  </MDBRow>
                </MDBCardHeader>
                <MDBCardBody className="h-100">
                  <MDBTabContent
                    className="px-3 py-1"
                    activeItem={activeItemJustified}
                  >
                    <MDBTabPane tabId="1">
                      <MDBRow>
                        {listaEventos === null ? (
                          <MDBSpinner className="mx-auto" red />
                        ) : listaEventos.length > 0 ? (
                          listaEventos.map((event) => (
                            <MDBCol className="p-2" size="12" md="6">
                              <MDBCard
                                className="border-1 border-light"
                                style={{ minHeight: '300px' }}
                              >
                                <MDBCardBody>
                                  <MDBRow between>
                                    <MDBCol size="12" lg="8">
                                      <MDBRow className="h4 ">
                                        {/* <MDBCol>{project.name}</MDBCol> */}
                                        <MDBCol className="text-jl font-weight-bold">
                                          {event.name}
                                        </MDBCol>
                                      </MDBRow>
                                      <MDBRow className="h5-responsive">
                                        {/* <MDBCol>Nickname: {project.nick_name}</MDBCol> */}
                                        <MDBCol className="grey-text"></MDBCol>
                                      </MDBRow>
                                      <MDBRow className="h6-responsive mt-3">
                                        {/* <MDBCol>Nickname: {project.nick_name}</MDBCol> */}
                                        <MDBCol className="grey-text">
                                          <i>
                                            Criado em: <br />
                                            {format(
                                              new Date(event.created_at),
                                              'dd/MM/yyyy HH:mm'
                                            )}
                                          </i>
                                        </MDBCol>
                                        <MDBCol className="grey-text">
                                          <i>
                                            Tipo de Evento: <br />
                                            {event.event_type.name} -{' '}
                                            {event.event_type.description}
                                          </i>
                                        </MDBCol>
                                      </MDBRow>
                                    </MDBCol>
                                    <MDBCol
                                      size="12"
                                      lg="4"
                                      className="right-text"
                                    >
                                      <MDBRow end>
                                        <MDBBtn
                                          onClick={() =>
                                            redirecionarEvent(event.uuid)
                                          }
                                          className="w-100 green-text font-weight-bold d-flex align-items-center justify-content-center button-jl"
                                          color="transparent"
                                          outline
                                          size="sm"
                                        >
                                          <MDBIcon
                                            style={{ fontSize: '14px' }}
                                            icon="folder-open"
                                            className="mr-2"
                                          />
                                          Abrir
                                        </MDBBtn>
                                      </MDBRow>
                                      <MDBRow end>
                                        <MDBBtn
                                          className="w-100 orange-text font-weight-bold d-flex align-items-center justify-content-center"
                                          outline
                                          color="none"
                                          size="sm"
                                        >
                                          <MDBIcon
                                            far
                                            style={{ fontSize: '14px' }}
                                            icon="edit"
                                            className="mr-2"
                                          />
                                          Alterar
                                        </MDBBtn>
                                      </MDBRow>
                                      <MDBRow end>
                                        <MDBBtn
                                          onClick={() => setArquivar(event)}
                                          className="w-100 red-text font-weight-bold d-flex align-items-center justify-content-center"
                                          outline
                                          color="none"
                                          size="sm"
                                        >
                                          <MDBIcon
                                            far
                                            style={{ fontSize: '14px' }}
                                            icon="file-archive"
                                            className="mr-2"
                                          />
                                          Arquivar
                                        </MDBBtn>
                                      </MDBRow>

                                      {/* <MDBRow className='text-right'>Status: {project.status}</MDBRow> */}
                                    </MDBCol>
                                  </MDBRow>

                                  <MDBRow className="mt-5 grey-text ">
                                    {event.sales_start_date && (
                                      <MDBCol
                                        className={`text-center font-weight-bolder ${
                                          isPast(new Date(event.sales_end_date))
                                            ? 'danger-text'
                                            : 'green-text'
                                        }`}
                                      >
                                        <MDBIcon icon="user-plus" size="2x" />

                                        <div
                                          style={{ fontSize: '12px' }}
                                          className="mt-2"
                                        >
                                          Período Captação
                                        </div>
                                        <div style={{ fontSize: '12px' }}>
                                          {format(
                                            new Date(event.capture_start_date),
                                            'dd/MM/yyyy'
                                          )}
                                          <br />
                                          {format(
                                            new Date(event.capture_end_date),
                                            'dd/MM/yyyy'
                                          )}
                                        </div>
                                      </MDBCol>
                                    )}

                                    <MDBCol className="text-center border-left border-light">
                                      {' '}
                                      <MDBIcon icon="link" size="2x" />
                                      <div
                                        style={{ fontSize: '12px' }}
                                        className="mt-2"
                                      >
                                        Visitas a Página
                                      </div>
                                      <div className="h5">12</div>
                                    </MDBCol>
                                    <MDBCol className="text-center border-left border-light">
                                      {' '}
                                      <MDBIcon icon="link" size="2x" />
                                      <div
                                        style={{ fontSize: '12px' }}
                                        className="mt-2"
                                      >
                                        Emails
                                      </div>
                                      <div className="h5">12</div>
                                    </MDBCol>
                                    {/* <MDBCol className="text-center border-left border-light">
                                      {' '}
                                      <MDBIcon icon="chart-line" size="2x" />
                                      <div
                                        style={{ fontSize: '12px' }}
                                        className="mt-2"
                                      >
                                        Conversão
                                      </div>
                                      <div className="h5">45,67%</div>
                                    </MDBCol> */}
                                    {event.sales_start_date && (
                                      <MDBCol
                                        className={`text-center  font-weight-bolder border-left border-light`}
                                      >
                                        <MDBIcon
                                          icon="hands-helping"
                                          size="2x"
                                          className={
                                            isPast(
                                              new Date(event.sales_end_date)
                                            )
                                              ? 'danger-text'
                                              : 'green-text'
                                          }
                                        />
                                        <div
                                          style={{ fontSize: '12px' }}
                                          className={`mt-2 ${
                                            isPast(
                                              new Date(event.sales_end_date)
                                            )
                                              ? 'danger-text'
                                              : 'green-text'
                                          }`}
                                        >
                                          Período Carrinho Aberto
                                        </div>
                                        <div
                                          style={{ fontSize: '12px' }}
                                          className={
                                            isPast(
                                              new Date(event.sales_end_date)
                                            )
                                              ? 'danger-text'
                                              : 'green-text'
                                          }
                                        >
                                          {format(
                                            new Date(event.sales_start_date),
                                            'dd/MM/yyyy'
                                          )}
                                          <br />
                                          {format(
                                            new Date(event.sales_end_date),
                                            'dd/MM/yyyy'
                                          )}
                                        </div>
                                      </MDBCol>
                                    )}
                                    <MDBCol className="text-center border-left border-light">
                                      {' '}
                                      <MDBIcon icon="eye" size="2x" />
                                      <div
                                        style={{ fontSize: '12px' }}
                                        className="mt-2"
                                      >
                                        Vendas Aprovadas
                                      </div>
                                      <div className="h5">12</div>
                                    </MDBCol>
                                    {/* <MDBCol className='text-center border-left border-light'> <MDBIcon far icon="eye" size='2x' />
                             <div style={{ fontSize: '12px' }} className='mt-2'>Visualizações</div>
                             <div className='h5'>45432</div>
                           </MDBCol> */}
                                  </MDBRow>
                                </MDBCardBody>
                              </MDBCard>
                            </MDBCol>
                          ))
                        ) : (
                          <MDBCol
                            size="12"
                            style={{ minHeight: '300px' }}
                            className="h3 grey-text text-center d-flex align-items-center justify-content-center font-weight-bold"
                          >
                            SEM EVENTOS ATIVOS
                          </MDBCol>
                        )}
                      </MDBRow>
                    </MDBTabPane>
                    <MDBTabPane tabId="2">
                      <MDBRow center>
                        {listaEventos === null ? (
                          <MDBSpinner red />
                        ) : listaEventos.length > 0 ? (
                          listaEventos.map((event) => (
                            <MDBCol className="p-2" size="12" md="6">
                              <MDBCard
                                className="border-1 border-light"
                                style={{ minHeight: '400px' }}
                              >
                                <MDBCardBody>
                                  <MDBRow between>
                                    <MDBCol size="12" lg="8">
                                      <MDBRow>
                                        {/* <MDBCol>{project.name}</MDBCol> */}
                                        <MDBCol
                                          size="12"
                                          className="h4 text-jl font-weight-bold"
                                        >
                                          {event.name}
                                        </MDBCol>
                                      </MDBRow>
                                      <MDBRow>
                                        <MDBCol
                                          size="12"
                                          className="text-left font-weight-bold h6-responsive red-text"
                                        >
                                          EVENTO ARQUIVADO
                                        </MDBCol>
                                      </MDBRow>
                                      <MDBRow className="h5-responsive">
                                        {/* <MDBCol>Nickname: {project.nick_name}</MDBCol> */}
                                        <MDBCol className="grey-text"></MDBCol>
                                      </MDBRow>
                                      <MDBRow className="h6-responsive mt-3">
                                        {/* <MDBCol>Nickname: {project.nick_name}</MDBCol> */}
                                        <MDBCol
                                          size="12"
                                          md="4"
                                          className="grey-text"
                                        >
                                          <i>
                                            Criado em: <br />
                                            <div className="font-weight-bold">
                                              {format(
                                                new Date(event.created_at),
                                                'dd/MM/yyyy HH:mm'
                                              )}
                                            </div>
                                          </i>
                                        </MDBCol>
                                        <MDBCol
                                          size="12"
                                          md="8"
                                          className="grey-text"
                                        >
                                          <i>
                                            Tipo de Evento: <br />
                                            <div className="font-weight-bold">
                                              {' '}
                                              {event.event_type.name} -{' '}
                                              {event.event_type.description}
                                            </div>
                                          </i>
                                        </MDBCol>
                                      </MDBRow>
                                    </MDBCol>
                                    <MDBCol
                                      size="12"
                                      lg="4"
                                      className="right-text"
                                    >
                                      <MDBRow end>
                                        <MDBBtn
                                          onClick={() => setReativar(event)}
                                          className="w-100 bg-success white-text font-weight-bold d-flex align-items-center justify-content-center"
                                          outline
                                          color="none"
                                          size="sm"
                                        >
                                          <MDBIcon
                                            far
                                            style={{ fontSize: '14px' }}
                                            icon="folder-open"
                                            className="mr-2"
                                          />
                                          Reativar
                                        </MDBBtn>
                                      </MDBRow>
                                    </MDBCol>
                                  </MDBRow>

                                  <MDBRow className="mt-5 grey-text ">
                                    {event.sales_start_date && (
                                      <MDBCol
                                        className={`text-center font-weight-bolder ${
                                          isPast(new Date(event.sales_end_date))
                                            ? 'danger-text'
                                            : 'green-text'
                                        }`}
                                      >
                                        <MDBIcon icon="user-plus" size="2x" />

                                        <div
                                          style={{ fontSize: '12px' }}
                                          className="mt-2"
                                        >
                                          Período Captação
                                        </div>
                                        <div style={{ fontSize: '12px' }}>
                                          {format(
                                            new Date(event.capture_start_date),
                                            'dd/MM/yyyy'
                                          )}
                                          <br />
                                          {format(
                                            new Date(event.capture_end_date),
                                            'dd/MM/yyyy'
                                          )}
                                        </div>
                                      </MDBCol>
                                    )}

                                    <MDBCol className="text-center border-left border-light">
                                      {' '}
                                      <MDBIcon icon="link" size="2x" />
                                      <div
                                        style={{ fontSize: '12px' }}
                                        className="mt-2"
                                      >
                                        Visitas a Página
                                      </div>
                                      <div className="h5">12</div>
                                    </MDBCol>
                                    <MDBCol className="text-center border-left border-light">
                                      {' '}
                                      <MDBIcon icon="link" size="2x" />
                                      <div
                                        style={{ fontSize: '12px' }}
                                        className="mt-2"
                                      >
                                        Quantidade de Leads
                                      </div>
                                      <div className="h5">12</div>
                                    </MDBCol>
                                    <MDBCol className="text-center border-left border-light">
                                      {' '}
                                      <MDBIcon icon="chart-line" size="2x" />
                                      <div
                                        style={{ fontSize: '12px' }}
                                        className="mt-2"
                                      >
                                        Conversão
                                      </div>
                                      <div className="h5">45,67%</div>
                                    </MDBCol>
                                    {event.sales_start_date && (
                                      <MDBCol
                                        className={`text-center  font-weight-bolder border-left border-light`}
                                      >
                                        <MDBIcon
                                          icon="hands-helping"
                                          size="2x"
                                          className={
                                            isPast(
                                              new Date(event.sales_end_date)
                                            )
                                              ? 'danger-text'
                                              : 'green-text'
                                          }
                                        />
                                        <div
                                          style={{ fontSize: '12px' }}
                                          className={`mt-2 ${
                                            isPast(
                                              new Date(event.sales_end_date)
                                            )
                                              ? 'danger-text'
                                              : 'green-text'
                                          }`}
                                        >
                                          Período Carrinho Aberto
                                        </div>
                                        <div
                                          style={{ fontSize: '12px' }}
                                          className={
                                            isPast(
                                              new Date(event.sales_end_date)
                                            )
                                              ? 'danger-text'
                                              : 'green-text'
                                          }
                                        >
                                          {format(
                                            new Date(event.sales_start_date),
                                            'dd/MM/yyyy'
                                          )}
                                          <br />
                                          {format(
                                            new Date(event.sales_end_date),
                                            'dd/MM/yyyy'
                                          )}
                                        </div>
                                      </MDBCol>
                                    )}
                                    <MDBCol className="text-center border-left border-light">
                                      {' '}
                                      <MDBIcon icon="eye" size="2x" />
                                      <div
                                        style={{ fontSize: '12px' }}
                                        className="mt-2"
                                      >
                                        Vendas Aprovadas
                                      </div>
                                      <div className="h5">12</div>
                                    </MDBCol>
                                    {/* <MDBCol className='text-center border-left border-light'> <MDBIcon far icon="eye" size='2x' />
                              <div style={{ fontSize: '12px' }} className='mt-2'>Visualizações</div>
                              <div className='h5'>45432</div>
                            </MDBCol> */}
                                  </MDBRow>
                                </MDBCardBody>
                              </MDBCard>
                            </MDBCol>
                          ))
                        ) : (
                          <MDBCol
                            size="12"
                            style={{ minHeight: '300px' }}
                            className="h3 grey-text text-center d-flex align-items-center justify-content-center font-weight-bold"
                          >
                            SEM EVENTOS ARQUIVADOS
                          </MDBCol>
                        )}
                      </MDBRow>
                    </MDBTabPane>
                    <MDBTabPane tabId="3" className="white">
                      <MDBRow className="p-3">
                        <MDBCol>
                          <Select placeholder="Selecione um Evento" />
                        </MDBCol>
                        <MDBCol>
                          <MDBCol>
                            <Select placeholder="Selecione uma Pagina" />
                          </MDBCol>
                        </MDBCol>
                        <MDBCol>
                          <MDBCol>
                            <Select placeholder="Selecione alguma coisa" />
                          </MDBCol>
                        </MDBCol>
                      </MDBRow>

                      <MDBRow className="mt-5 grey-text ">
                        <MDBCol className="text-center danger-text">
                          <MDBIcon icon="user-plus" size="2x" />

                          <div style={{ fontSize: '12px' }} className="mt-2">
                            Período Captação
                          </div>
                          <div style={{ fontSize: '12px' }}>
                            27/12/2021
                            <br />
                            16/01/2023
                          </div>
                        </MDBCol>
                        <MDBCol className="text-center border-left border-light">
                          {' '}
                          <MDBIcon icon="link" size="2x" />
                          <div style={{ fontSize: '12px' }} className="mt-2">
                            Visitas a Página
                          </div>
                          <div className="h5">12</div>
                        </MDBCol>
                        <MDBCol className="text-center border-left border-light">
                          {' '}
                          <MDBIcon icon="link" size="2x" />
                          <div style={{ fontSize: '12px' }} className="mt-2">
                            Visitas a Página
                          </div>
                          <div className="h5">12</div>
                        </MDBCol>
                        <MDBCol className="text-center border-left border-light">
                          {' '}
                          <MDBIcon icon="link" size="2x" />
                          <div style={{ fontSize: '12px' }} className="mt-2">
                            Visitas a Página
                          </div>
                          <div className="h5">12</div>
                        </MDBCol>
                        <MDBCol className="text-center border-left border-light">
                          {' '}
                          <MDBIcon icon="link" size="2x" />
                          <div style={{ fontSize: '12px' }} className="mt-2">
                            Visitas a Página
                          </div>
                          <div className="h5">12</div>
                        </MDBCol>
                        <MDBCol className="text-center border-left border-light">
                          {' '}
                          <MDBIcon icon="link" size="2x" />
                          <div style={{ fontSize: '12px' }} className="mt-2">
                            Quantidade de Leads
                          </div>
                          <div className="h5">12</div>
                        </MDBCol>
                        <MDBCol className="text-center border-left border-light">
                          {' '}
                          <MDBIcon icon="chart-line" size="2x" />
                          <div style={{ fontSize: '12px' }} className="mt-2">
                            Conversão
                          </div>
                          <div className="h5">45,67%</div>
                        </MDBCol>
                        <MDBCol className="text-center  border-left border-light ">
                          <MDBIcon
                            icon="hands-helping"
                            size="2x"
                            className="green-text"
                          />
                          <div
                            style={{ fontSize: '12px' }}
                            className="mt-2 green-text"
                          >
                            Período Carrinho Aberto
                          </div>
                          <div
                            style={{ fontSize: '12px' }}
                            className="green-text"
                          >
                            27/12/2021
                            <br />
                            16/01/2022
                          </div>
                        </MDBCol>
                        <MDBCol className="text-center border-left border-light">
                          {' '}
                          <MDBIcon icon="eye" size="2x" />
                          <div style={{ fontSize: '12px' }} className="mt-2">
                            Vendas Aprovadas
                          </div>
                          <div className="h5">12</div>
                        </MDBCol>
                        {/* <MDBCol className='text-center border-left border-light'> <MDBIcon far icon="eye" size='2x' />
                              <div style={{ fontSize: '12px' }} className='mt-2'>Visualizações</div>
                              <div className='h5'>45432</div>
                            </MDBCol> */}
                      </MDBRow>
                      <MDBRow>
                        <MDBCol size="12" md="6">
                          {' '}
                          <Minimalist />
                        </MDBCol>
                        <MDBCol size="12" md="6">
                          {' '}
                          <Line />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol size="12" md="4">
                          <Bars />
                        </MDBCol>
                        <MDBCol size="12" md="4">
                          <HorizontalBar />
                        </MDBCol>
                        <MDBCol size="12" md="4">
                          <Pie />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBRow>
                          <MDBCol size="12" md="6">
                            {' '}
                            <Polar />
                          </MDBCol>
                          <MDBCol size="12" md="6">
                            {' '}
                          </MDBCol>
                        </MDBRow>
                      </MDBRow>
                    </MDBTabPane>
                  </MDBTabContent>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>

          <div className="classic-tabs py-2 ">
            {/* <MDBNav >
              <MDBNavItem >
                <MDBNavLink className="border-danger  py-2" link to="#" active={activeItemJustified === "1"} onClick={() => toggleJustified("1")} role="tab" >
                  <MDBRow className={`px-4 ${activeItemJustified === "1" ? "red-text" : "grey-text "}`} >
                    <MDBIcon far icon="file-archive" size='2x' />
                    <div className='ml-2 font-weight-bold d-flex align-items-center'>Ativos</div>
                  </MDBRow>
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem >
                <MDBNavLink className="border-danger  py-2" link to="#" active={activeItemJustified === "2"} onClick={() => toggleJustified("2")} role="tab" >
                  <MDBRow className={`px-4 ${activeItemJustified === "2" ? "red-text" : "grey-text "}`} >
                    <MDBIcon far icon="file-archive" size='2x' />
                    <div className='ml-2 font-weight-bold d-flex align-items-center'>Arquivados</div>
                  </MDBRow>
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem >
                <MDBNavLink className="border-danger  py-2" link to="#" active={activeItemJustified === "3"} onClick={() => toggleJustified("3")} role="tab" >
                  <MDBRow className={`px-4 ${activeItemJustified === "3" ? "red-text" : "grey-text "}`} >
                    <MDBIcon far icon="file-archive" size='2x' />
                    <div className='ml-2 font-weight-bold d-flex align-items-center'>Gráficos</div>
                  </MDBRow>
                </MDBNavLink>
              </MDBNavItem>



            </MDBNav> */}
          </div>

          {/* <MDBRow start className='m-0 p-0 '>
            <MDBCol size='12' md='2' className='mt-3 m-0 p-0 '>
              <MDBNavLink link to="#" active={activeItemJustified === "1"} onClick={() => toggleJustified("1")} role="tab" >
                <MDBCard className={`${activeItemJustified === "1" ? "elegant-color-dark text-white" : "grey-text "}`}>
                  <MDBCardBody >
                    <MDBRow center >
                      <MDBCol size='4' className='d-flex align-items-center' ><MDBIcon icon='user' size='2x' /></MDBCol>
                      <MDBCol size='8' className='text-center' >
                        <MDBRow center className='font-weight-bold'>Ativos</MDBRow>
                        <MDBRow center className='h4'>12</MDBRow>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </MDBNavLink>
            </MDBCol>
            <MDBCol className='mt-3 m-0 p-0 ' size='12' md='2'>
              <MDBNavLink link to="#" active={activeItemJustified === "2"} onClick={() => toggleJustified("2")} role="tab" >
                <MDBCard className={`${activeItemJustified === "2" ? "elegant-color-dark text-white" : "grey-text "}`}>
                  <MDBCardBody >
                    <MDBRow center >
                      <MDBCol size='4' className='d-flex align-items-center' ><MDBIcon far icon="file-archive" size='2x' /></MDBCol>
                      <MDBCol size='8' className='text-center' >
                        <MDBRow center className='font-weight-bold'>Arquivados</MDBRow>
                        <MDBRow center className='h4'>321</MDBRow>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </MDBNavLink>
            </MDBCol>

            <MDBCol className='mt-3' size='12' md='2'>
              <MDBNavLink link to="#" active={activeItemJustified === "5"} onClick={() => toggleJustified("5")} role="tab" >
                <MDBCard className={`${activeItemJustified === "5" ? "elegant-color-dark text-white" : "grey-text "}`}>
                  <MDBCardBody >
                    <MDBRow center >
                      <MDBCol size='4' className='d-flex align-items-center' ><MDBIcon far icon="handshake" size='2x' /></MDBCol>
                      <MDBCol size='8' className='text-center' >
                        <MDBRow center className='font-weight-bold'>Dashboard</MDBRow>
                        <MDBRow center className='h4'>4555</MDBRow>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </MDBNavLink>
            </MDBCol>
          </MDBRow>

 */}

          {/* </MDBCardBody>
            </MDBCard> */}
          {/* <br /> */}
          {/* {projeto.domain.map((domain, index) => (

            <MDBCard className='mx-3' key={index}>
              <MDBCardBody>
                <MDBRow className='mt-3' >

                  <MDBCol size='12' md='6' className='h4-responsive'>
                    Domínio:
                  </MDBCol>
                  <MDBCol size='12' md='6' className='h4-responsive'>
                    {domain.domain}
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol size='12' className='h4-responsive'>

                    criado em:{format(new Date(domain.created_at), 'dd/MM/yyyy HH:mm')}
                  </MDBCol>
                  <MDBCol size='12' className='h4-responsive'>

                    Status:{domain.active ? 'Ativo' : 'Inativo'}
                  </MDBCol>
                  <MDBCol size='12' className='h4-responsive'>
                    Descrição
                    {domain.description}
                  </MDBCol>

                </MDBRow>
              </MDBCardBody>
            </MDBCard>

          ))} */}
        </>
      )}
      <MDBModal isOpen={novoEventoModal} size="lg">
        <MDBModalHeader>Novo Evento</MDBModalHeader>
        <MDBModalBody>
          <MDBRow>
            <MDBCol>
              <MDBInput
                outline
                label="Nome do Evento"
                counter={50}
                maxLength="50"
              />
            </MDBCol>
            <MDBCol>
              <div>Tipo de lançamento</div>
              <Select
                options={typeEvent}
                onChange={(e) => setTypeEventSelecionado(e)}
              />
            </MDBCol>
          </MDBRow>

          {/* <MDBRow>
            <MDBCol>
              <MDBInput style={{borderRadius:'5px'}} outline type="textarea" label="Descrição do Evento" rows="2"   counter={12} maxLength='12' />

            </MDBCol>
          </MDBRow> */}
          <MDBRow>
            {typeEventSelecionado?.capture_date && (
              <MDBCol className="font-weight-bold grey-text">
                <MDBRow center>PERÍODO DE CAPTAÇÃO DE LEADS</MDBRow>
                <MDBRow center>
                  <MDBCol>
                    <MDBRow center>
                      <div className="mr-3 d-flex align-items-center">
                        INICIAL
                      </div>
                      <MDBDatePicker
                        format="DD-MM-YYYY"
                        className="p-0 m-0 ml-2 d-flex justify-content-start"
                        // getValue={(date) => handleDateChange(date, 'start')}
                        value={captacaoStartDate}
                      />
                    </MDBRow>
                    <MDBRow center>
                      <div
                        style={{ marginRight: '21px' }}
                        className="d-flex align-items-center"
                      >
                        FINAL
                      </div>
                      <MDBDatePicker
                        format="DD-MM-YYYY"
                        className="p-0 m-0 ml-2 d-flex justify-content-start W-100"
                        // getValue={(date) => handleDateChange(date, 'start')}
                        // value={startDate}
                      />
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            )}
            {typeEventSelecionado?.sales_date && (
              <MDBCol className="font-weight-bold grey-text">
                <MDBRow center>PERÍODO DE CARRINHO ABERTO</MDBRow>
                <MDBRow center>
                  <MDBCol>
                    <MDBRow center>
                      <div className="mr-3 d-flex align-items-center ">
                        INICIAL
                      </div>
                      <MDBDatePicker
                        format="DD-MM-YYYY"
                        className="p-0 m-0 ml-2 d-flex justify-content-start"
                        // getValue={(date) => handleDateChange(date, 'start')}
                        // value={startDate}
                      />
                    </MDBRow>
                    <MDBRow center>
                      <div
                        style={{ marginRight: '21px' }}
                        className="d-flex align-items-center"
                      >
                        FINAL
                      </div>
                      <MDBDatePicker
                        format="DD-MM-YYYY"
                        className="p-0 m-0 ml-2 d-flex justify-content-start W-100"
                        // getValue={(date) => handleDateChange(date, 'start')}
                        // value={startDate}
                      />
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            )}
          </MDBRow>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            size="sm"
            onClick={() => setNovoEventoModal(false)}
            color="danger"
          >
            Cancelar
          </MDBBtn>
          <MDBBtn size="sm" color="elegant">
            Salvar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>

      <MDBModal isOpen={reativar !== null} size="lg">
        <MDBModalHeader>REATIVAR EVENTO</MDBModalHeader>
        <MDBModalBody>
          <MDBRow>
            <MDBCol>Deseja realmente reativar o evento:</MDBCol>
          </MDBRow>
          <MDBRow>
            {/* <MDBCol>{project.name}</MDBCol> */}
            <MDBCol size="12" className="h5">
              {reativar?.name}
            </MDBCol>
          </MDBRow>
          <MDBRow className="h5-responsive">
            {/* <MDBCol>Nickname: {project.nick_name}</MDBCol> */}
            <MDBCol className="grey-text"></MDBCol>
          </MDBRow>
          <MDBRow className="h6-responsive mt-3">
            {/* <MDBCol>Nickname: {project.nick_name}</MDBCol> */}
            <MDBCol size="12" md="4" className="grey-text">
              <i>
                Criado em: <br />
                {reativar !== null && (
                  <div className="font-weight-bold">
                    {format(new Date(reativar?.created_at), 'dd/MM/yyyy HH:mm')}
                  </div>
                )}
              </i>
            </MDBCol>
            <MDBCol size="12" md="8" className="grey-text">
              <i>
                Tipo de Evento: <br />
                {reativar !== null && (
                  <div className="font-weight-bold">
                    {' '}
                    {reativar?.event_type.name} -{' '}
                    {reativar?.event_type.description}
                  </div>
                )}
              </i>
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn size="sm" onClick={() => setReativar(null)} color="danger">
            Cancelar
          </MDBBtn>
          <MDBBtn
            size="sm"
            onClick={() => AtivarArquivarEvento(reativar.event_id, true)}
            color="elegant"
          >
            Reativar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>

      <MDBModal isOpen={arquivar !== null} size="lg">
        <MDBModalHeader>ARQUIVAR EVENTO</MDBModalHeader>
        <MDBModalBody>
          <MDBRow>
            <MDBCol>Deseja realmente arquivar o evento:</MDBCol>
          </MDBRow>
          <MDBRow>
            {/* <MDBCol>{project.name}</MDBCol> */}
            <MDBCol size="12" className="h5">
              {arquivar?.name}
            </MDBCol>
          </MDBRow>
          <MDBRow className="h5-responsive">
            {/* <MDBCol>Nickname: {project.nick_name}</MDBCol> */}
            <MDBCol className="grey-text"></MDBCol>
          </MDBRow>
          <MDBRow className="h6-responsive mt-3">
            {/* <MDBCol>Nickname: {project.nick_name}</MDBCol> */}
            <MDBCol size="12" md="4" className="grey-text">
              <i>
                Criado em: <br />
                {arquivar !== null && (
                  <div className="font-weight-bold">
                    {format(new Date(arquivar?.created_at), 'dd/MM/yyyy HH:mm')}
                  </div>
                )}
              </i>
            </MDBCol>
            <MDBCol size="12" md="8" className="grey-text">
              <i>
                Tipo de Evento: <br />
                {arquivar !== null && (
                  <div className="font-weight-bold">
                    {' '}
                    {arquivar?.event_type.name} -{' '}
                    {arquivar?.event_type.description}
                  </div>
                )}
              </i>
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn size="sm" onClick={() => setArquivar(null)} color="danger">
            Cancelar
          </MDBBtn>
          <MDBBtn
            size="sm"
            onClick={() => AtivarArquivarEvento(arquivar.event_id, false)}
            color="elegant"
          >
            Arquivar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </MDBContainer>
  );
}
