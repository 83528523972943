import React, { useEffect,useState,useRef } from 'react';

import { MDBContainer, MDBRow, MDBCol,MDBBox,MDBCollapse, MDBSelect, MDBBtn,MDBCard,MDBCardTitle,MDBCardBody,MDBCardText, MDBInput } from 'mdbreact';
import api from '../../services/api'
import { toast } from 'react-toastify';

// import Api from '../../services/api';

// import { Container } from './styles';


export default function Company() {
  const [newProject,setNewProject]=useState(false) 

  const [functionlityList,setFunctionlityList] = useState([])
  const [newName,setNewName]=useState('')
  const [newDescription,setNewDescription]=useState('')
 

  const listFunc = async()=>{
    try {
      const result = await api.get('/functionality')
      setFunctionlityList(result.data)
      console.log(result.data)
    } catch (error) {
      toast.error('Functionalitys error')
    }
    
  }

  const newFunc = async()=>{
    let name =  newName.trim();
    let description = newDescription.trim();
    if(name === '' || description === '') {
      toast.error('Fields not filled in')
      return
    }
    if(name.length > 50 || description.length > 200) {
      toast.error('Maximum Characters Exceeded')
      return
    }
    
    
    
    try {
      const result = await api.post('/functionality',{ name, description})
    if(result.data.error){
      toast.error(result.data.error)
      return
    }
      toast.success('Successfully created')
    } catch (e) {
      toast.error(e.error)
    }
  }


  useEffect(()=>{
    listFunc()
  },[])


  return (
    <>
     
        <MDBContainer fluid className='pt-4'>
        
       
          <MDBRow between>
            <MDBCol size='6' md='4'>
            <MDBBtn color="indigo" size='sm' onClick={()=>setNewProject(!newProject)}>New Functionality</MDBBtn>
            </MDBCol>
            <MDBCol size='6' md='4' className='text-center'>
            <MDBBox className="h3" >FUNCTIONALITY</MDBBox>
            </MDBCol>
            <MDBCol size='0' md='4'></MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
          <MDBCollapse id="newProject" isOpen={newProject}>
          <MDBCard >
        {/* <MDBCardImage className="img-fluid" src="https://mdbootstrap.com/img/Photos/Others/images/43.webp" waves /> */}
        <MDBCardBody>
          <MDBCardTitle>New Functionality</MDBCardTitle>
         
            <MDBRow>
              
           
              <MDBCol size='12' md='4'><MDBInput outline label='Name' type='text' counter={50} maxLength={50} value={newName} onChange={(e)=>setNewName(e.target.value)} /></MDBCol>
              <MDBCol size='12' md='8'><MDBInput outline label='Description' type="textarea" counter={200} maxLength={200} value={newDescription} onChange={(e)=>setNewDescription(e.target.value)}  /></MDBCol>
              
              </MDBRow>          
          
         
          <MDBBtn size='sm' color='green' onClick={newFunc}>Create</MDBBtn>
      
        </MDBCardBody>
      </MDBCard>
        </MDBCollapse>
        </MDBCol>
          </MDBRow>
          <MDBRow className='mt-5 h4' left>
            <MDBCol>Main List</MDBCol>
   
          </MDBRow>

          <MDBRow className='mt-5 h4' left>
          <MDBCol>Archived List</MDBCol>
           
          </MDBRow>

          
         

       
        </MDBContainer>
      
    </>
  );
}
