import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBSelect } from 'mdbreact';

// import Api from '../../services/api';

// import { Container } from './styles';

export default function App() {
  return (
    <>
     
        <MDBContainer fluid className='pt-4'>
        <MDBRow>
          <MDBCol> Selecione o Dominio:</MDBCol>
          <MDBCol> <MDBSelect /></MDBCol>
         

        </MDBRow>
        </MDBContainer>
      
    </>
  );
}
