import React from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBSimpleChart,
} from 'mdbreact';

class ChartsPagePro extends React.Component {
  render() {
    return (
      <MDBContainer>
        <div>
          <MDBContainer>
            <MDBRow className="text-center d-flex justify-content-center align-items-center">
              <MDBCol sm="4">
                <MDBSimpleChart
                  width={100}
                  height={100}
                  strokeWidth={5}
                  percent={76}
                  strokeColor="#EA3C3B"
                  labelFontWeight="300"
                  labelColor="#000"
                />
                <h6 className="mt-5">
                  <span className="label red p-1 white-text">
                    <strong>Vendas Aprovadas</strong>

                    <MDBIcon icon="arrow-circle-down" className="ml-1" />
                  </span>
                </h6>
              </MDBCol>
              <MDBCol sm="4">
                <MDBSimpleChart
                  width={100}
                  height={100}
                  strokeWidth={3}
                  percent={100}
                  strokeColor="#9D9D9D"
                  labelFontWeight="300"
                  labelColor="#000"
                />
                <h6 className="mt-5">
                  <span className="label grey p-1 white-text">
                    <strong>Vendas Confirmadas</strong>
                    <MDBIcon icon="minus-square" className="ml-1" />
                  </span>
                </h6>
              </MDBCol>
              <MDBCol sm="4">
                <MDBSimpleChart
                  width={100}
                  height={100}
                  strokeWidth={3}
                  percent={100}
                  strokeColor="#9D9D9D"
                  labelFontWeight="300"
                  labelColor="#000"
                />
                <h6 className="mt-5">
                  <span className="label grey p-1 white-text">
                    <strong>Carrinhos Abandonados</strong>
                    <MDBIcon icon="minus-square" className="ml-1" />
                  </span>
                </h6>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      </MDBContainer>
    );
  }
}

export default ChartsPagePro;
