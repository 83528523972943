import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { isAuthenticated } from './services/auth';

import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';

import App from './pages/App';

import {Layout} from './components/Layout'

import Projetos from './pages/Projetos';
import ShowProjetos from './pages/Projetos/Show';
import Functionality from './pages/Functionality';
import Eventos from './pages/Eventos';
// import AddEventos from './pages/Eventos/Add';

// import Perfil from './pages/Perfil';




const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Layout>
          <Component {...props} />
        </Layout>
      ) : (
        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      )
    }
  />
);


const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route  path="/login" component={SignIn} />
      <Route  path="/signup" component={SignUp} />
      <PrivateRoute exact path="/" component={App} />
      <PrivateRoute exact path="/projetos" component={Projetos} />
      <PrivateRoute  path="/projetos/:uuid" component={ShowProjetos} />
      <PrivateRoute  path="/functionality" component={Functionality} />
      <PrivateRoute  path="/eventos" component={Eventos} />
      {/* <PrivateRoute  path="/:projeto_uuid/add-evento" component={AddEventos} /> */}
      <PrivateRoute  path="/event/:projeto_uuid/:event_uuid" component={Eventos} />

      <Route path="*" component={() => <h1>Page not found</h1>} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
