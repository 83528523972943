import React, { useState, useEffect } from 'react';
import {
  MDBRow,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
} from 'mdbreact';
import { Link, useLocation, useHistory } from 'react-router-dom';
import Select from 'react-select';
import Logo from '../../assets/jlbranco.png';

import Api from '../../services/api';

const NavbarPage = () => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const [projetos, setProjetos] = useState([]);
  // Obtém a localização atual
  const location = useLocation();

  const listaProjetos = async () => {
    try {
      const result = await Api.get('/projetos');
      const listaSelect = [];
      result.data.forEach((element) => {
        listaSelect.push({ label: element.name, value: element.uuid });
      });

      setProjetos(listaSelect);
    } catch (error) {
      console.log(error);
    }
  };

  const redirecionarProjeto = (id) => {
    history.push(`/projetos/${id}`);
  };

  // Função para verificar se o caminho é ativo
  const isActive = (path) => location.pathname === path;

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    listaProjetos();
  }, []);
  return (
    <MDBNavbar color="unique-color-dark" dark expand="md">
      <MDBNavbarBrand className="font-weight-bold d-flex align-items-center">
        <img src={Logo} style={{ height: '50px' }} alt="logo jornada lead" />
        <div className="ml-2">JORNADA LEAD</div>
      </MDBNavbarBrand>
      <MDBNavbarToggler onClick={toggleCollapse} />
      <MDBCollapse id="navbarCollapse3" isOpen={isOpen} navbar>
        <MDBNavbarNav left className="ml-0 ml-md-5">
          <MDBNavItem active={isActive('/')}>
            <MDBNavLink to="/">
              <MDBIcon icon="home" className="mr-2" />
              Home
            </MDBNavLink>
          </MDBNavItem>
          {/* <MDBNavItem>
            <MDBNavLink to="/login">
              {' '}
              <MDBIcon icon="chart-bar" className="mr-2" />
              Dashboards
            </MDBNavLink>
          </MDBNavItem> */}

          <MDBNavItem>
            <MDBNavLink to="#!">
              <MDBIcon icon="dollar-sign" className="mr-2" /> Pricing
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem active={isActive('/projetos')}>
            <MDBNavLink to="/projetos">
              <MDBIcon icon="dollar-sign" className="mr-2" /> Projects
            </MDBNavLink>
          </MDBNavItem>
          {/* <MDBNavItem active={isActive('/eventos')}>
            <MDBNavLink to="/eventos">
              <MDBIcon icon="dollar-sign" className="mr-2" /> Eventos
            </MDBNavLink>
          </MDBNavItem> */}
        </MDBNavbarNav>
        <MDBNavbarNav className="d-flex align-items-center" right>
          {/* <MDBNavItem>
              <MDBNavLink className="waves-effect waves-light" to="#!">
                <MDBIcon fab icon="twitter" />
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink className="waves-effect waves-light" to="#!">
                <MDBIcon fab icon="google-plus-g" />
              </MDBNavLink>
            </MDBNavItem> */}
          <MDBNavItem style={{ width: '300px' }} active={isActive('/projetos')}>
            <Select
              options={projetos}
              onChange={(e) => redirecionarProjeto(e.value)}
              placeholder="Selecione seu Projeto"
              className="mx-3 font-weight-bold"
            />
          </MDBNavItem>
          <MDBNavItem>
            <MDBDropdown>
              <MDBDropdownToggle
                style={{ fontSize: '16px' }}
                color="unique-color-dark"
                className="text-white z-depth-0"
                caret
                right
              >
                <MDBIcon icon="user" />
              </MDBDropdownToggle>
              <MDBDropdownMenu color="ins" right>
                <MDBDropdownItem>
                  <Link to="/profile">Profile</Link>
                </MDBDropdownItem>
                <MDBDropdownItem>
                  <Link to="/change-password">Change Password</Link>
                </MDBDropdownItem>
                <MDBDropdownItem>
                  <Link to="/projects">Invites</Link>
                </MDBDropdownItem>
                <MDBDropdownItem>
                  <Link to="/login">Logout</Link>
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBNavItem>
        </MDBNavbarNav>
      </MDBCollapse>
    </MDBNavbar>
  );
};

export default NavbarPage;
