import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBox,
  MDBIcon,
  MDBNav,
  MDBTabContent,
  MDBTabPane,
  MDBNavItem,
  MDBNavLink,
  MDBCollapse,
  MDBTypography,
  MDBBtn,
  MDBCard,
  MDBCardTitle,
  MDBCardBody,
  MDBCardText,
  MDBInput,
  MDBCardHeader,
} from 'mdbreact';
import { useHistory, useParams } from 'react-router-dom';
import { format, isPast } from 'date-fns';
import Api from '../../services/api';

// import { Container } from './styles';

export default function Eventos() {
  const { projeto_uuid, event_uuid } = useParams();
  const history = useHistory();
  const [newProject, setNewProject] = useState(false);

  const [newNameProject, setNewNameProject] = useState('');
  const [newNicknameProject, setNewNicknameProject] = useState('');
  const [newDescriptionProject, setNewDescriptionProject] = useState('');

  const [eventSelected, setEventSelected] = useState(null);
  const [numeroTab, setNumeroTab] = useState('1');

  const [tiposPages, setTiposPages] = useState([]);

  const redirecionar = (id) => {
    // Substitua '/sua-rota' pelo caminho para o qual você deseja redirecionar
    history.push(`projetos/${id}`);
  };

  const createNewProject = async () => {
    try {
      const result = await Api.post('/projetos', {
        name: newNameProject,
        nick_name: newNicknameProject,
        description: newDescriptionProject,
      });
      console.log('projetos', result);
      // setProjects([result.data, ...projects])
    } catch (error) {
      console.log(error);
    }
  };

  const getEvent = async () => {
    try {
      const result = await Api.get(`/event/${event_uuid}`);
      setEventSelected(result.data);
      // listaTiposPages(result.data.event_id);
    } catch (error) {
      console.log(error);
    }
  };

  const listaTiposPages = async (event_id) => {
    try {
      const result = await Api.get(`/type-page/${event_id}`);

      setTiposPages(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  const toogleTabs = (tab) => {
    if (numeroTab !== tab) {
      setNumeroTab(tab);
    }
  };

  useEffect(() => {
    getEvent();
  }, []);

  useEffect(() => {
    eventSelected?.event_id && listaTiposPages(eventSelected.event_id);
  }, [eventSelected]);

  return (
    <>
      <MDBContainer fluid>
        <MDBRow>
          <MDBCol size="12" md="5" className="d-flex mt-3">
            <MDBCard className="w-100 flex-grow-1">
              <MDBCardHeader>
                <MDBRow>
                  <MDBCol className="font-weight-bolder mx-2 grey-text h4-responsive">
                    PROJETO
                  </MDBCol>
                  <MDBCol className="grey-text text-right">
                    <i>
                      Criado em:
                      {eventSelected?.project.created_at &&
                        format(
                          new Date(eventSelected.project.created_at),
                          'dd/MM/yyyy'
                        )}
                    </i>
                  </MDBCol>
                </MDBRow>
              </MDBCardHeader>
              <MDBCardBody className="d-flex flex-column">
                <MDBRow between>
                  <MDBCol size="12" className="pl-4">
                    <MDBRow className="h4 font-weight-bold text-jl">
                      <MDBCol>{eventSelected?.project.name}</MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol className="grey-text h5 font-weight-bold">
                        {eventSelected?.project.nick_name}
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                  <MDBCol size="12">
                    <MDBRow className="mt-4">
                      {/* <MDBCol>Nickname: {project.nick_name}</MDBCol> */}
                      <MDBCol className="grey-text ml-2">
                        Descrição: <br />
                        {eventSelected?.project.description}
                      </MDBCol>
                    </MDBRow>
                    <MDBRow></MDBRow>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol size="12" md="7" className="d-flex mt-3">
            <MDBCard className="w-100 flex-grow-1">
              <MDBCardHeader>
                <MDBRow>
                  <MDBCol className="font-weight-bolder mx-2 grey-text h4-responsive">
                    EVENTO
                  </MDBCol>
                  <MDBCol className="grey-text text-right">
                    <i>
                      Criado em:
                      {eventSelected?.created_at &&
                        format(
                          new Date(eventSelected.created_at),
                          'dd/MM/yyyy'
                        )}
                    </i>
                  </MDBCol>
                </MDBRow>
              </MDBCardHeader>
              <MDBCardBody className="d-flex flex-column">
                <MDBRow between>
                  <MDBCol size="12" lg="6" className="pl-4">
                    <MDBRow className="h4 font-weight-bold text-jl">
                      <MDBCol>{eventSelected?.name}</MDBCol>
                    </MDBRow>
                    <MDBRow className="mt-4">
                      <MDBCol size="12" className="grey-text">
                        Tipo de Evento: {eventSelected?.event_type.name}
                      </MDBCol>
                      {/* <MDBCol size='12' className='grey-text'>{eventSelected?.event_type.description}</MDBCol> */}
                    </MDBRow>
                  </MDBCol>
                  {eventSelected?.event_type.capture_date === true && (
                    <MDBCol
                      style={
                        isPast(new Date(eventSelected.capture_end_date))
                          ? { color: 'rgba(255,0,0,0.6)' }
                          : { color: 'rgba(0,150,0,0.7)' }
                      }
                      className={`text-center font-weight-bolder `}
                    >
                      <MDBIcon icon="user-plus" size="2x" />

                      <div style={{ fontSize: '12px' }} className="mt-2">
                        Período Captação
                      </div>
                      <div style={{ fontSize: '12px' }}>
                        {format(
                          new Date(eventSelected.capture_start_date),
                          'dd/MM/yyyy'
                        )}
                        <br />
                        {format(
                          new Date(eventSelected.capture_end_date),
                          'dd/MM/yyyy'
                        )}
                      </div>
                    </MDBCol>
                  )}
                  {eventSelected?.event_type.sales_date === true && (
                    <MDBCol
                      style={
                        isPast(new Date(eventSelected.sales_end_date))
                          ? { color: 'rgba(255,0,0,0.6)' }
                          : { color: 'rgba(0,150,0,0.7)' }
                      }
                      className={`text-center  font-weight-bolder`}
                    >
                      <MDBIcon icon="hands-helping" size="2x" />
                      <div style={{ fontSize: '12px' }} className={`mt-2`}>
                        Período Carrinho Aberto
                      </div>
                      <div style={{ fontSize: '12px' }}>
                        {format(
                          new Date(eventSelected.sales_start_date),
                          'dd/MM/yyyy'
                        )}
                        <br />
                        {format(
                          new Date(eventSelected.sales_end_date),
                          'dd/MM/yyyy'
                        )}
                      </div>
                    </MDBCol>
                  )}
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        <MDBRow className="mt-3">
          <MDBCol size="12" md="12" className="d-flex">
            <MDBCard className="w-100 flex-grow-1">
              <MDBCardHeader className="p-0">
                <MDBRow between className="m-2 d-flex align-items-center">
                  <MDBCol className="font-weight-bolder mx-2 grey-text h4-responsive">
                    PAGES
                  </MDBCol>
                  <MDBCol className=" d-flex align-items-center justify-content-end">
                    <MDBBtn
                      color="green"
                      className="m-0 p-2 d-flex align-items-center justify-content-center"
                      size="sm"
                    >
                      {' '}
                      <MDBIcon icon="plus" size="2x" />
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBCardHeader>
              <MDBCardBody className="d-flex flex-column">
                <MDBRow between>
                  <MDBCol size="12" className="pl-4">
                    {tiposPages.map(
                      (tipo) =>
                        tipo.page.length > 0 && (
                          <>
                            <div className="mb-5">
                              <MDBRow className="grey-text  font-weight-bold py-2 h5">
                                <MDBCol>TIPO: {tipo.name}</MDBCol>
                                <MDBCol>Descricao: {tipo.description}</MDBCol>
                              </MDBRow>
                              <MDBRow className="font-weight-bolder grey-text">
                                <MDBCol>URLs</MDBCol>
                              </MDBRow>
                              {tipo.page.map((page) => (
                                <MDBRow>
                                  <MDBCol>{page.url}</MDBCol>
                                  <MDBCol>{page.description}</MDBCol>
                                </MDBRow>
                              ))}
                            </div>
                            <hr />
                          </>
                        )
                    )}
                    {/* {eventSelected?.page.map((page) => (
                      <>
                        <MDBRow>
                          <MDBCol>NOME:</MDBCol>
                          <MDBCol>{page.url}</MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol>descricao do evento:</MDBCol>
                          <MDBCol>{page.description}</MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol>Type:</MDBCol>
                          <MDBCol>{page.page_type.name}</MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol>Descricao:</MDBCol>
                          <MDBCol>{page.page_type.description}</MDBCol>
                        </MDBRow>

                        <br />
                        <br />
                        <br />
                      </>
                    ))} */}
                    <MDBRow></MDBRow>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}
