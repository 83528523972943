import axios from 'axios';
import { getToken } from './auth';

const api = axios.create({
  // baseURL: 'http://localhost:3333',
  baseURL: 'https://api.jornadalead.com.br', // production
});

api.interceptors.request.use(async (config) => {
  const token = await getToken();
  const configuration = config;

  if (token) {
    configuration.headers.Authorization = `Bearer ${token}`;
  }
  return configuration;
});

export default api;
