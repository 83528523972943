import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Logo from '../../assets/JL.jpeg';
import api from '../../services/api';
import { Form, Container } from './styles';
import { toast } from 'react-toastify';

const SignUp = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      toast.error('Fill in all the fields!');
    } else {
      try {
        const result = await api.post('/users', { email, password });
        console.log(result.data)
        toast.success('Registrado com sucesso');
        history.push('/');
        return
      } catch (error) {
        console.log(error);
        toast.error('Error occurred while registering');
      }
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSignUp}>
        <img src={Logo} alt="Airbnb logo" />
        
        {/* <input
          type="text"
          placeholder="Nome de usuário"
          onChange={(e) => setUsername(e.target.value)}
        /> */}
        <input
          type="email"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Free</button>
        <hr />
        <Link to="/login">SignIn</Link>
      </Form>
    </Container>
  );
};

export default SignUp;
