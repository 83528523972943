import React, { useEffect } from 'react';
import { useState } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBox,MDBIcon, MDBCollapse, MDBTypography, MDBBtn, MDBCard, MDBCardTitle, MDBCardBody, MDBCardText, MDBInput } from 'mdbreact';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Api from '../../services/api';

// import { Container } from './styles';


export default function Project() {
  const history = useHistory();
  const [newProject, setNewProject] = useState(false)


  const [newNameProject, setNewNameProject] = useState('')
  const [newNicknameProject, setNewNicknameProject] = useState('')
  const [newDescriptionProject, setNewDescriptionProject] = useState('')


  const [projects, setProjects] = useState([])

  const redirecionar = (id) => {
    // Substitua '/sua-rota' pelo caminho para o qual você deseja redirecionar
    history.push(`projetos/${id}`);
  };

  const createNewProject = async () => {
    try {
      const result = await Api.post('/projetos', { name: newNameProject, nick_name: newNicknameProject, description: newDescriptionProject })
      setProjects([result.data, ...projects])
    } catch (error) {
      console.log(error)
    }

  }

  const listaProjetos = async () => {
    try {
      const result = await Api.get('/projetos')
     
      setProjects(result.data)
    } catch (error) {
      toast.error('Error ao carregar o tipos')
    }

  }

  useEffect(() => {
    listaProjetos()
  }, [])
  return (
    <>

      <MDBContainer fluid className='pt-4'>

        <MDBRow>
          <MDBCol className='mb-3' size='12' md='4'>
            <MDBTypography tag='div' className='h-100 mb-3 d-flex flex-column justify-content-between' note noteColor='danger' noteTitle='ATENÇÃO: '>
              <div>Você ainda não é um usuário PREMIUM, para ter acesso a todas as funcionalidades, faça o upgrade de sua conta.</div>
              <MDBRow center>
                <MDBBtn style={{ maxWidth: '150px' }} className='font-weight-bolder' color="green" size='sm'>Seja Premium</MDBBtn>
              </MDBRow>
            </MDBTypography>
          </MDBCol>

          <MDBCol className='mb-3' size='12' md='4'>
            <MDBTypography tag='div' className='h-100 mb-3 d-flex flex-column justify-content-between' note noteColor='warning' noteTitle='ATENÇÃO: '>
              <div>Termine de preencher seu perfil para ter acesso a algumas funcionalidades.</div>
              <MDBRow center>
                <MDBBtn style={{ maxWidth: '150px' }} className='font-weight-bolder' color="green" size='sm'>Perfil</MDBBtn>
              </MDBRow>
            </MDBTypography>
          </MDBCol>


          <MDBCol className='mb-3' size='12' md='4'>
            <MDBTypography tag='div' className='h-100 mb-3 d-flex flex-column justify-content-between' note noteColor='warning' noteTitle='ATENÇÃO: '>
              <div>Você precisa de um projeto ativo criar seu script</div>
              <MDBRow center>
                <MDBBtn style={{ maxWidth: '150px' }} className='font-weight-bolder' color="green" size='sm'>Perfil</MDBBtn>
              </MDBRow>
            </MDBTypography>
          </MDBCol>


        </MDBRow>
        <MDBRow between className='mt-3'>

          <MDBCol size='6' md='4'>
            <MDBBtn color="indigo" size='sm' onClick={() => setNewProject(!newProject)}>Novo Projeto</MDBBtn>
          </MDBCol>
          <MDBCol size='6' md='4' className='text-center'>
            <MDBBox className="h3" >PROJETOS</MDBBox>
          </MDBCol>
          <MDBCol size='0' md='4'>

          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <MDBCollapse id="newProject" isOpen={newProject}>
              <MDBCard >
                {/* <MDBCardImage className="img-fluid" src="https://mdbootstrap.com/img/Photos/Others/images/43.webp" waves /> */}
                <MDBCardBody>

                  <MDBCardTitle>Novo Projeto</MDBCardTitle>
                  <MDBRow center>
                    <MDBCol size='12' md='3'>
                      <MDBInput onChange={(e) => { setNewNameProject(e.target.value) }} value={newNameProject} outline label="Nome" type='text' />
                    </MDBCol>
                    <MDBCol size='12' md='3'>
                      <MDBInput onChange={(e) => { setNewNicknameProject(e.target.value) }} value={newNicknameProject} outline label="Nickname" type='text' />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow center>
                    <MDBCol size='6'>
                      <MDBInput onChange={(e) => { setNewDescriptionProject(e.target.value) }} value={newDescriptionProject} outline label="Descrição" type='textarea' />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow center>
                  <MDBBtn size='sm' color='green' onClick={() => createNewProject()}>Criar</MDBBtn>
                  </MDBRow>
                  
                </MDBCardBody>
              </MDBCard>
            </MDBCollapse>
          </MDBCol>
        </MDBRow>
        <MDBRow className='mt-5 h4' left>
          <MDBCol>Meus Projetos</MDBCol>
        </MDBRow>
        <MDBRow>
          {projects.map((project, index) => {
            return (
              <MDBCol key={project.id} size='12' md='4' className='mt-3'>
                <MDBCard>
                  <MDBCardBody>
                    <MDBRow between>
                      <MDBCol size='12' lg='8' className='pl-4'>
                        <MDBRow className='h4'>
                          <MDBCol>{project.name}</MDBCol>
                        </MDBRow>
                        <MDBRow className='h5-responsive' >
                          <MDBCol>Nickname: {project.nick_name}</MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol>{project.description}</MDBCol>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol size='12' lg='4' className='right-text'>
                        <MDBRow end  >
                          <MDBBtn onClick={()=>redirecionar(project.uuid)} className='w-100 green-text font-weight-bold d-flex align-items-center justify-content-center button-jl' color='transparent' outline   size='sm'>
                           
                          <MDBIcon  style= {{fontSize:'14px'}} icon="folder-open" className='mr-2' />
                            Abrir
                            </MDBBtn>
                        </MDBRow>
                        <MDBRow end >
                   
                          <MDBBtn  className='w-100 orange-text font-weight-bold d-flex align-items-center justify-content-center' outline color='none' size='sm'>
                          <MDBIcon far style= {{fontSize:'14px'}} icon="edit" className='mr-2' />
                            Alterar</MDBBtn>
                        </MDBRow>
                        <MDBRow end >
                          <MDBBtn className='w-100 red-text font-weight-bold d-flex align-items-center justify-content-center' outline color='none' size='sm'>
                          <MDBIcon far style= {{fontSize:'14px'}} icon="trash-alt" className='mr-2' />
                            Desativar</MDBBtn>
                        </MDBRow>
                        
                        {/* <MDBRow className='text-right'>Status: {project.status}</MDBRow> */}
                      </MDBCol>
                    </MDBRow>

                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            )
          })}</MDBRow>






      </MDBContainer>

    </>
  );
}
