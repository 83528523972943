import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Logo from '../../assets/JL.jpeg';
import api from '../../services/api';
import { login } from '../../services/auth';
import { Form, Container } from './styles';
import { toast } from 'react-toastify';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const history = useHistory();

  const handleSignIn = async (e) => {
    e.preventDefault();
 

    if (!email || !password) {
      toast.error('Fill in email and password');
    } else {
      try {

        
        const response = await api.post('/session', { email, password });
       
        const dados = {
          // nome: response.data.nome,
          // sobrenome: response.data.sobrenome,
          email: response.data.email,
          // foto: response.data.arquivo,
        };

        login(response.data.token, dados);

        history.push('/');
      } catch (error) {
        toast.error(`Access denied!`);
      }
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSignIn}>
        <img style={{ borderRadius: '10px' }} src={Logo} alt="logik vmi" />
     
        
        <input
          type="email"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">SIGN IN</button>
        <hr />
        <Link to="/signup">Criar conta</Link>
      </Form>
    </Container>
  );
};

export default SignIn;
